.dashboard{
    display: flex;
    flex-direction: column;
    width:100wh;
    height: 100vh;
}
.Header{
height: 50px;
display: flex;
justify-content: space-between;
align-items: center;
padding: 4px 30px 4px 20px;
border-bottom: 1px solid rgba(0,0,0,0.15);
padding-left: 5px;
}
.Footer{
height: 50px;
display: flex;
justify-content: space-evenly;
align-items: center;
border-top: 1px solid rgba(0,0,0,0.15);
}
.SideMenupageContent{
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex:1;
}

.PageContent{
    padding-left: 12px;
}
.SideMenu{
    height: 100%;
}
.tableConatin{
    width:12px;
}
.CardFullLength{
    justify-content: space-between;
    border-width: thick
}
.UserButton{
    
}
.Card{
    width:100vw;
    height: 100vh;
}   
.PageTitle{
    font-family: 'Brush Script MT';
}

.content {
    padding: 50px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

.SideMenuVertical{
    height: 100%;
}

.DetailBox{
    width:'-webkit-fill-available'
}

.CursorChange{
    cursor: pointer;
}